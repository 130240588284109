import React from "react";
import { LOGO_IMG } from '../Common/assets_paths'
import NavMenu from "./NavMenu";
import { Router } from 'react-router';
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: '',
            search_location: 'user',
            top_admin_email: props.top_admin_email
        }
    }
    _handleKeyDown = event => {
        if (event.key === 'Enter') {
            this.doSearch();
        }
    }
    doSearch = () => {
        if(this.state.searchTerm.trim().length > 0){
            window.location.href = '/admin/search/'+this.state.searchTerm+'/location/'+this.state.search_location;
        }
        else{
            if(this.props.is_top_admin){
                    window.location.href = '/admin/users/paid'
            }
            else{
                if(this.props.is_distributor){
                    window.location.href = '/admin/distributor/view/'+this.props.p_id
                }
                else if (this.props.is_reseller){
                    window.location.href = '/admin/reseller/view/'+this.props.p_id
                }
                else if (this.props.is_institution_admin) {
                    window.location.href = '/admin/institution/view/'+this.props.p_id
                }
            }
        }
    }
    handleChange = event => {
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value
        });
        if(event.target.name == 'search_location'){
            this.setState({searchTerm: this.props.location.pathname.split('/search/')[1].split('/')[0]});
            setTimeout(() => {
                this.doSearch()
            }, 0)
        }
    }
    signout = event => {
        localStorage.removeItem('activeKey');
        window.location.href = "/logout";
    }
    render() {
        return (
            <div className="admin-header-main">
                <div className="pl-1 admin-header-logo">
                    <a href="/"><img src={LOGO_IMG} /></a>
                </div>
                <div className="admin-menu-options">
                    <NavMenu {...this.props} top_admin_email={this.state.top_admin_email}/>
                </div>
                <div className="admin-right-options">
                    <div className="admin-search-general">
                        <div className="search-input" title={i18n.t('admin.placeholder.search_box')}>
                            {/* data-i18n="[placeholder]file.open:search-placeholder"  */}
                            <input onKeyDown={this._handleKeyDown} onChange={this.handleChange} name="searchTerm" type="text" className="form-control" id="glb_input_search_text" placeholder={i18n.t('admin.placeholder.search_box')} />
                            <a href={void (0)} onClick={this.doSearch} className="lh-icon-os-search btn-search" id="btn_search_in"></a>
                            <select className="form-control" id="glb_search_location" name="search_location" onChange={this.handleChange}>
                                <option value={"user"}>Users</option>
                                <option value={"institute"}>Teams</option>
                                <option value={"distributor"}>Distributors</option>
                                <option value={"invitation"}>Invitations</option>
                                <option value={"all"}>All</option>
                            </select>
                        </div>
                    </div>
                    <div className="admin-navigate-back">
                        <a href="/oldview" target="_blank" className="text-accenta">{i18n.t('admin.labels.old_view')}</a>
                    </div>
                    <div className="admin-btn-logout">
                        <a href={void (0)} onClick={this.signout} id="user-signout" className="tool"><span className="text-white lh-icon-os-signout"></span></a>
                    </div>
                </div>
            </div>

        )
    }
}

export default withTranslation() (Header)