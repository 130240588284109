import React from "react";
import LandingStepBase from "../Common/Layouts/LandingStepBase";
import { withRouter } from "react-router-dom";
import i18n from "../../packs/i18n";
import Errors from "../Common/Errors/Errors";
import API from "../api";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../Common/TextValidator";
import CheckboxValidator from "../Common/CheckboxValidator";
import { getLocalUrl, getQueryParam, getAvailableLanguages, getPrivacyURL } from "../Common/i3library";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import { withTranslation } from "react-i18next";
import { Geo_IMG, Ruler_IMG } from '../Common/assets_paths';
import LanguageSelection from '../LanguageOptions/LanguageSelection';

class ConnectSession extends React.Component {
  constructor(props) {
    super(props);
    const language = this.props.match.params ? this.props.match.params.locale : 'en';
    const languagesOptions = getAvailableLanguages();
    this.state = {
      name: "",
      code: "",
      customCheck1: false,
      customCheck2: false,
      hasError: false,
      errorMessage: "",
      redirect: false,
      isValidate: false,
      from: "",
      token: "",
      status: null,
      language: language,
      languageOpt: languagesOptions,
    };

    ValidatorForm.addValidationRule("isChecked", value => {
      return value;
    });
  }
  componentDidMount() {
    const values = getQueryParam(this.props.location.search);
    if (values.code) {
      this.setState({
        code: values.code
      });
    }
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleOnError = errors => {
    this.setState({
      isValidate: true,
      hasError: true,
      errorMessage: getErrorMessage(errors, true),
      status: null
    });
  };

  handleSubmit = event => {
    const { name, code, customCheck1, customCheck2 } = this.state;
    const terms_accepted = customCheck1 && customCheck2 ? true : false;
    const params = {
      quick_connect_entry: {
        user_name: name
      },
      code: code,
      terms_accepted: terms_accepted,
      commit: "Connect"
    };
    const _this = this;
    API.post("en/start", params)
      .then(res => {
        window.location.href = res.data.redirect_to;
      })
      .catch(function(error) {
        if (error.response) {
          _this.setState({
            hasError: true,
            errorMessage: error.response.data.message,
            status: error.response.status
          });
        }
        if (error.request) {
          console.log(error.request);
        } else {
          _this.setState({
            hasError: true,
            errorMessage: error.message,
            status: error.status
          });
        }
      })
      .finally(function() {
        // always executed
      });
  };

  handleLanguageChange= (event) => {
    const languageVal = event.target.value;
    API.get(`user_sessions/language?lang=${languageVal}`)
      .then(res => {
        const codeValue = this.state.code ? `?code=${this.state.code}` : "";
        window.location.href = `/${languageVal}/start${codeValue}`;
      })
      .catch(function(error) {
        if (error.response) {
          _this.setState({
            hasError: true,
            errorMessage: error.response.data.message,
            status: error.response.status
          });
        }
        if (error.request) {
          console.log(error.request);
        } else {
          _this.setState({
            hasError: true,
            errorMessage: error.message,
            status: error.status
          });
        }
      })
      .finally(function() {
        // always executed
      });
  }

  render() {
    return (
      <React.Fragment>
        <LandingStepBase
          container={
            <div className="container">
              <div className="row">
                <div className="col-sm-12 p-0 text-center">
                  <div className="headtxt animated fadeInDown mt-5">
                    <h1 className="text-white">{i18n.t("connect.title")}</h1>
                    <p>{i18n.t("connect.sub_title")}</p>
                  </div>
                  <div className="main-wrapper">
                    <ValidatorForm
                      ref="form"
                      onSubmit={this.handleSubmit}
                      onError={this.handleOnError}
                      instantValidate={false}
                      className="white-cover text-left"
                    >
                      <div className="col-sm-12 pb-3 pl-0 pr-0">
                        <TextValidator
                          className={"form-control form-control-lg"}
                          autoComplete="off"
                          placeholder={i18n.t("connect.enter_name")}
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          isValidate={this.state.isValidate}
                          noValidate
                          validators={["required"]}
                          errorMessages={[
                            i18n.t("connect.errors.name_required")
                          ]}
                        />
                      </div>

                      <TextValidator
                        className={"form-control form-control-lg"}
                        autoComplete="off"
                        placeholder={i18n.t("connect.enter_code")}
                        type="text"
                        name="code"
                        value={this.state.code}
                        onChange={this.handleChange}
                        isValidate={this.state.isValidate}
                        noValidate
                        validators={["required"]}
                        errorMessages={[
                          i18n.t("connect.errors.session_code_required")
                        ]}
                      />

                      <div className="custom-control custom-checkbox my-4">
                        <CheckboxValidator
                          className={"custom-control-input"}
                          autoComplete="off"
                          type="checkbox"
                          name="customCheck1"
                          id="customCheck1"
                          value={this.state.customCheck1}
                          onChange={e => this.handleChange(e, "checkbox")}
                          isValidate={this.state.isValidate}
                          noValidate
                          labelclass="custom-control-label text-accentb text-size-18"
                          label={i18n.t("signup.step1.accept_tos")}
                          validators={["isChecked"]}
                          errorMessages={[
                            i18n.t("signup.step1.errors.tos_required")
                          ]}
                        />
                        &nbsp; <a href={`/`+this.state.language+`/tos`} target="no_blank" className="text-accentb text-size-18 text-uline">{i18n.t('signup.step1.accept_tos_link_txt')}</a>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <CheckboxValidator
                          className={"custom-control-input"}
                          autoComplete="off"
                          type="checkbox"
                          name="customCheck2"
                          id="customCheck2"
                          value={this.state.customCheck2}
                          onChange={e => this.handleChange(e, "checkbox")}
                          isValidate={this.state.isValidate}
                          noValidate
                          labelclass="custom-control-label text-accentb text-size-18"
                          label={i18n.t("signup.step1.accept_pol")}
                          validators={["isChecked"]}
                          errorMessages={[
                            i18n.t("signup.step1.errors.pol_required")
                          ]}
                        />
                        &nbsp; <a target="no_blank" href={getPrivacyURL()} className="text-accentb text-size-18 text-uline">{i18n.t('signup.step1.accept_pol_link_txt')}</a>
                      </div>
                      <button
                        type="submit"
                        className={"btn btn-accenta btn-lg btn-block mt-4"}
                      >
                        {i18n.t("connect.connect_button")}
                      </button>
                    </ValidatorForm>
                    <Errors
                      hasError={this.state.hasError}
                      status={this.state.status}
                      type="warning"
                      message={this.state.errorMessage}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        ></LandingStepBase>
        <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="connect lang-top-right" code={this.state.code} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(ConnectSession));
