/* This is a place where we can create a common functions which required to use multiple location

*/
import cookie from 'react-cookies';
import moment from 'moment-timezone';
let user = null;

let available_languages = null;
let privacy_url = null;
let pack_team_pref_arr = null;
//Function for check email address is valid or not.
export const  ENABLEQR = 'i3enable_qr';
export const validateEmail = (email) =>{
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
}
export let IS_RELOAD_INFO = false;
export const createCookie = (name,value,days) => {
    const expires = new Date()
    expires.setDate(Date.now() + days * 60 * 60 * 24 * 14)
    cookie.save(
        name,
        value,
        {
          path: '/',
          expires,
          maxAge: days
        }
    )
}

export const readCookie = (name) => {
    return cookie.load(name)
}

export const debounce = (fn, delay) => {
    let timer = null;
    return function (...args) {
        const context = this;
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}

export const getLocalUrl = (locale,url) => {
    if(!locale)
        locale = 'en';
   return '/'+locale+'/'+url;
}

export const setUserInfo = (info) => {
    user = info;
}

export const updateUserInfo = (data) => {
    user = Object.assign(user, data);
}

export const getUserInfo = () => {
    return user;
}
export const getIsUserInfoReload = () => {
    return IS_RELOAD_INFO;
}

export const setIsUserInfoReload = (val) => {
    IS_RELOAD_INFO = val;
}
export const setAvailableLanguages = (languages) => {
    if(!languages)
        return;
    let _languages = [];
    languages.forEach(element => {
        _languages.push({label:element[0],value:element[1]})
    });
    available_languages = _languages;
}

export const getAvailableLanguages = () => {
    return available_languages;
}
export const setTeamPackPrefixArray = (prefix_arr) => {
    if(!prefix_arr)
        return
    pack_team_pref_arr = prefix_arr
}
export const getTeamPackPrefixArray = () => {
    return pack_team_pref_arr;
}
export const setPrivacyURL = (url) => {
    if(!url)
        return;
    privacy_url = url;
}
export const getPrivacyURL = () => {
    return privacy_url;
}
export const getLocalUrlFromUrl = (locale, url) => {
    const pathArray = window.location.pathname.split('/');
    if(pathArray.length > 0) {
        locale = ['nl', 'en', 'fr'].indexOf(pathArray[1])  > -1 ? pathArray[1] : null; 
    }
    if(!locale)
        locale = 'en';
   return '/'+locale+'/'+url;
}

export const getUrlParameter = (name, url) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    if(!url) {
        url = location.search;
    }
    var results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function checkIsadminAndIsTopAdmin (currentUser) {
    const top_admin = currentUser.top_admin;
    const capabilities = currentUser.capabilities;
    const data = { top_admin : !!top_admin , isAdmin : false };
    if(capabilities){
        data.isAdmin = capabilities.admin;
    }
    return data;
};

export function isProTagAdd() {
    let isFree = false;
    const currentUser = getUserInfo();
    const daysRem = currentUser.days_to_pay;
    const userType = currentUser.account_type;
    const checkAdmin = checkIsadminAndIsTopAdmin(currentUser);
    let account_type = currentUser.capabilities != undefined ? currentUser.account_type.charAt(0).toUpperCase() + currentUser.account_type.slice(1) : 'free';
    if (daysRem <= 0 && userType != "admin") {
        account_type = "Free";
    }


    if (account_type.toUpperCase() == "FREE"){
        isFree = true;
    }
    if (checkAdmin.top_admin || checkAdmin.isAdmin){
        isFree = false;
    }
    return isFree;
    // if(!info || info.days_to_pay === null || info.toUpperCase() == "FREE") {
    //     return true;
    // }
    // return false;
}

export function getQueryParam(string) {
    return  decodeURI(string)
            .replace('?', '')
            .split('&')
            .map(param => param.split('='))
            .reduce((values, [ key, value ]) => {
                values[ key ] = value
                return values
            }, {})
}

export function getUTCTime(){
    let currentTime = moment.tz();
    return currentTime.utc().format();
}