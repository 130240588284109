import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import Errors from '../Common/Errors/Errors';
import API from '../api';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../Common/TextValidator';
import CheckboxValidator from '../Common/CheckboxValidator';
import { getLocalUrl, getQueryParam, getAvailableLanguages, getUTCTime } from '../Common/i3library';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import { withTranslation } from 'react-i18next';
import LanguageSelection from '../LanguageOptions/LanguageSelection';
import {createEvent} from '../Common/AnalyticsEvents'
import {getPrivacyURL} from '../Common/i3library'

class SignUp extends React.Component{
    constructor(props) {
        super(props);
        const language = this.props.match.params ? this.props.match.params.locale : 'en';
        const languagesOptions = getAvailableLanguages();
        this.state = {
          email: "",
          password: "",
          customCheck1:false,
          customCheck2:false,
          hasError:false,
          errorMessage: "",
          redirect: false,
          isValidate: false,
          from: "",
          token: "",
          oauth: false,
          status: null,
          language: language,
          languageOpt: languagesOptions,
          url_locale: props.match.params.locale,
          dynemichtml: false
        };

        ValidatorForm.addValidationRule('isChecked', (value) => {
            return value;
        });
    }
    componentDidMount() {
      const values = getQueryParam(this.props.location.search)
      if(values.email) {
        this.setState({
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          from: values.from,
          invite_token: values.token,
          oauth: values.oauth
        });
      }
    }
    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.name == 'email' ? event.target.value.trim() : event.target.value
        });
    }

    handleOnError = errors => {
        this.setState({
            isValidate: true,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    }

    handleSubmit = event => {
        // Validation Removed Temporarly due to some major issues 
        /*if(this.state.from !== "login" && this.state.password.trim().length <= 0){
            this.setState({
                hasError: true,
                password: '',
                errorMessage: i18n.t('login.errors.password_required')
            })
            return;
        }*/
        const user = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            language: this.props.match.params.locale,
            language: this.props.match.params.locale,
            from: this.state.from,
            invite_token: this.state.invite_token
        };
        const _this = this;
        API.post('react_landing/register', user)
            .then(res => {
                if(res.data.redirectPath){
                    createEvent("Signup", "Signup sucess for invited user")
                }
                else{
                    if(this.state.from !== "login")
                    {
                        
                        createEvent("Signup", "Step1",getUTCTime())
                    }
                }
                this.props.history.push({
                    pathname: res.data.redirect_path || getLocalUrl(this.props.match.params.locale, this.state.from === 'login' ? `signup/selectpackage/${res.data.persistence_token}` : 'signup/confirmemail'),
                    state: { first_name: this.state.first_name,
                        last_name: this.state.last_name,
                        email: this.state.email }
                });
            })
            .catch(function (error) {
                if(error.response){
                    if(error.response.status === 409){
                        createEvent("Signup", "Signup Fail Due to user already exist",error.response.message)
                        _this.setState({
                            hasError: true,
                            errorMessage: i18n.t('login.errors.already_exist_with_link'),
                            dynemichtml: true,
                            status: error.response.status
                        })
                    }
                    else{
                        createEvent("Signup", "Signup Fail Due to unknown reason", error.response.message);
                        _this.setState({
                            hasError: true,
                            errorMessage: i18n.t('login.errors.wrong_credential'),
                            status: error.response.status
                        })
                    }

                }
                if(error.request){
                console.log(error.request);
                }
                else{
                 createEvent("Signup", "Signup Fail Due to unknown reason", error.message);
                _this.setState({
                    hasError: true,
                    errorMessage: error.message,
                    status: error.status
                })
                }
            })
            .finally(function () {
                // always executed
            });

    }

    render(){
        return (
          <React.Fragment>
            <LandingStepBase container={
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 p-0 text-center">
                    <div className="headtxt animated fadeInDown mt-5">
                        <h1 className="text-white">{i18n.t('signup.step1.header.title')}</h1>
                        <p>{i18n.t('signup.step1.header.sub_title')}</p>
                        { this.state.from !== 'invite' &&
                            (
                                <React.Fragment>
                                    <div className="steps">
                                        <span className="activestep step mr-2 ml-2"></span><span className="step  mr-2 ml-2"></span><span className="step  mr-2 ml-2"></span><span className="step mr-2 ml-2"></span><span className="step mr-2 ml-2"></span>
                                    </div>
                                    <p className="mt-2 text-quaternary text-size-20">{i18n.t('signup.step1.header.current_step')}</p>
                                </React.Fragment>
                            )
                        }
                    </div>
                    <div className="main-wrapper">
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={this.handleOnError}
                            instantValidate={false}
                            className="white-cover text-left"
                        >
                            {!this.state.invite_token &&
                            <div className="col-sm-12 pb-3 pl-0 pr-0">
                                <TextValidator
                                    className={'form-control form-control-lg'}
                                    autoComplete="off"
                                    placeholder={i18n.t('signup.step1.enter_first_name')}
                                    type="text"
                                    name="first_name"
                                    value={this.state.first_name || ""}
                                    onChange={this.handleChange}
                                    isValidate={this.state.isValidate}
                                    noValidate
                                    disabled={this.state.first_name !== "" && this.state.from !== "" && (this.state.oauth && (this.state.first_name)) ? "disabled" : ""}
                                    validators={['required', "maxStringLength:40"]}
                                    errorMessages={[i18n.t('login.errors.first_name_required'), i18n.t('login.errors.first_name_max_length')]} />
                            </div>
                            }
                            {!this.state.invite_token &&
                            <div className="col-sm-12 pb-3 pl-0 pr-0">
                                <TextValidator
                                    className={'form-control form-control-lg'}
                                    autoComplete="off"
                                    placeholder={i18n.t('signup.step1.enter_last_name')}
                                    type="text"
                                    name="last_name"
                                    value={this.state.last_name || ""}
                                    onChange={this.handleChange}
                                    isValidate={this.state.isValidate}
                                    noValidate
                                    disabled={this.state.last_name !== "" && this.state.from !== "" && (this.state.oauth && (this.state.last_name)) ? "disabled" : ""}
                                    validators={['required', "maxStringLength:40"]}
                                    errorMessages={[i18n.t('login.errors.last_name_required'), i18n.t('login.errors.last_name_max_length')]} />
                            </div>
                            }
                            <div className="col-sm-12 pb-3 pl-0 pr-0">
                                <TextValidator
                                    className={'form-control form-control-lg'}
                                    autoComplete="off"
                                    placeholder={i18n.t('signup.step1.enter_email')}
                                    type="text"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    isValidate={this.state.isValidate}
                                    noValidate
                                    disabled={this.state.email !== "" && this.state.from !== "" ? "disabled" : ""}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[i18n.t('login.errors.email_required'), i18n.t('login.errors.email_invalid')]} />
                            </div>
                            { !this.state.oauth && <div className="col-sm-12 pb-2 pl-0 pr-0">
                                                            <TextValidator
                                                                className={'form-control form-control-lg'}
                                                                autoComplete="off"
                                                                placeholder={i18n.t('signup.step1.enter_pass')}
                                                                type="password"
                                                                name="password"
                                                                value={this.state.password}
                                                                onChange={this.handleChange}
                                                                isValidate={this.state.isValidate}
                                                                noValidate
                                                                validators={['required', 'minStringLength:8']}
                                                                errorMessages={[i18n.t('login.errors.password_required'), i18n.t('signup.step1.errors.password_max_char')]} />
                                                        </div> }
                            <div className="custom-control custom-checkbox my-4">
                                <CheckboxValidator
                                    className={'custom-control-input'}
                                    autoComplete="off"
                                    type="checkbox"
                                    name="customCheck1"
                                    id="customCheck1"
                                    value={this.state.customCheck1}
                                    onChange={(e) => this.handleChange(e, 'checkbox')}
                                    isValidate={this.state.isValidate}
                                    noValidate
                                    labelclass="custom-control-label text-accentb text-size-18"
                                    label={i18n.t('signup.step1.accept_tos')}
                                    validators={['isChecked']}
                                    errorMessages={[i18n.t('signup.step1.errors.tos_required')]} />
                                &nbsp; <a href={`/`+this.state.language+`/tos`} target="no_blank" className="text-accentb text-size-18 text-uline">{i18n.t('signup.step1.accept_tos_link_txt')}</a>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <CheckboxValidator
                                    className={'custom-control-input'}
                                    autoComplete="off"
                                    type="checkbox"
                                    name="customCheck2"
                                    id="customCheck2"
                                    value={this.state.customCheck2}
                                    onChange={(e) => this.handleChange(e, 'checkbox')}
                                    isValidate={this.state.isValidate}
                                    noValidate
                                    labelclass="custom-control-label text-accentb text-size-18"
                                    label={i18n.t('signup.step1.accept_pol')}
                                    validators={['isChecked']}
                                    errorMessages={[i18n.t('signup.step1.errors.pol_required')]} />
                                &nbsp; <a target="no_blank" href={getPrivacyURL()} className="text-accentb text-size-18 text-uline">{i18n.t('signup.step1.accept_pol_link_txt')}</a>
                            </div>
                            <button type="submit" className={'btn btn-accenta btn-lg btn-block mt-4'}> {i18n.t('signup.step1.btn_continue')}</button>
                        </ValidatorForm>
                        <Errors hasError={this.state.hasError} status={this.state.status} type="warning" dynemichtml= {this.state.dynemichtml || false} message={this.state.errorMessage}/>
                    </div>
                  </div>
                  <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="mobile mt-3" redirectPath={"signup"+this.props.location.search} />
                </div>
              </div>}>
            </LandingStepBase>
            <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="desktop" redirectPath={"signup"+this.props.location.search} />
          </React.Fragment>
        );
    }

}

export default withTranslation()(SignUp);
