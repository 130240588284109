import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { getLocalUrl, getQueryParam, getAvailableLanguages, getUTCTime } from '../Common/i3library';
import LanguageSelection from '../LanguageOptions/LanguageSelection';
import Errors from '../Common/Errors/Errors';
import API from '../api';
import { ValidatorForm } from 'react-form-validator-core';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import TextValidator from '../Common/TextValidator';
import CheckboxValidator from '../Common/CheckboxValidator';
import {getPrivacyURL} from '../Common/i3library'
import countryList from 'react-select-country-list';
import SelectValidator from '../Common/SelectValidator';
import {createEvent} from '../Common/AnalyticsEvents'

class PackSignUp extends React.Component{
    constructor(props) {
        super(props);
        const language = this.props.match.params ? this.props.match.params.locale : 'en';
        const pack_key = this.props.match.params ? this.props.match.params.pack_key : null;
        const languagesOptions = getAvailableLanguages()
        const countryOptions = countryList().getData();
        //ILEARN-4927 Tiwan Name Update 
        let taiwan_item = countryOptions.find(item => item.value == 'TW');
        taiwan_item.label = i18n.t("userprofile.labels.tiwan_label");
        let tiwan_index = countryOptions.findIndex(item => item.value == 'TW');
        countryOptions[tiwan_index] = taiwan_item;
        //ILEARN-4927 Tiwan Name Update End
        this.state = {
          language: language,
          languageOpt: languagesOptions,
          countryOpt: countryOptions,
          url_locale: props.match.params.locale,
          pack_key: pack_key,
          next_page_url : "/packsignup/confirmemail",
          hasError:false,
          errorMessage: "",
          isValidate: false,
          status: null,
          email: "",
          password: "",
          customCheck1:false,
          customCheck2:false,
          url_locale: props.match.params.locale,
          dynemichtml: false,
          pathname : pack_key ? "packsignup/"+pack_key+"/register" : "packsignup/register"
        }
        ValidatorForm.addValidationRule('isChecked', (value) => {
            return value;
        });
    }
    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.name == 'email' ? event.target.value.trim() : event.target.value
        });
    }

    handleOnError = errors => {
        this.setState({
            isValidate: true,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    }

    handleSubmit = event => {
        const user = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            language: this.state.language,
            pack_key : this.state.pack_key,
            from: "packsignup"
        };
        const _this = this;
        API.post('react_landing/register', user)
            .then(res => {
                if(res.data.redirectPath){
                    createEvent("PackSignup", "Signup sucess for invited user")
                }
                else{
                    if(this.state.from !== "login")
                    {
                        
                        createEvent("PackSignup", "Step1",getUTCTime())
                    }
                }
                this.props.history.push({
                    pathname: this.state.next_page_url,
                    state: { first_name: this.state.first_name,
                        last_name: this.state.last_name,
                        email: this.state.email }
                });
            })
            .catch(function (error) {
                if(error.response){
                    if(error.response.status === 409){
                        createEvent("PackSignup", "Signup Fail Due to user already exist",error.response.message)
                        _this.setState({
                            hasError: true,
                            errorMessage: i18n.t('login.errors.already_exist_with_link'),
                            dynemichtml: true,
                            status: error.response.status
                        })
                    }
                    else{
                        createEvent("PackSignup", "Signup Fail Due to unknown reason", error.response.message);
                        _this.setState({
                            hasError: true,
                            errorMessage: i18n.t('login.errors.wrong_credential'),
                            status: error.response.status
                        })
                    }

                }
                if(error.request){
                console.log(error.request);
                }
                else{
                 createEvent("PackSignup", "Signup Fail Due to unknown reason", error.message);
                _this.setState({
                    hasError: true,
                    errorMessage: error.message,
                    status: error.status
                })
                }
            })
            .finally(function () {
                // always executed
            });
    }
    render(){
        return (
          <React.Fragment>
            <LandingStepBase pack_signup={true} container={
                <div className="container"> 
                     <div className="row" style={{pointerEvents: this.state.disableClick ? 'none' : 'all'}}>
                        <div className="col-sm-12 p-0 text-center">
                            <div className="headtxt animated fadeInDown mt-5">
                                <h1 className="text-white">{i18n.t('signup.step1.header.title')}</h1>
                                <p>{i18n.t('signup.step1.header.sub_title')}</p>
                                <div className="steps">
                                    <span className="activestep step mr-2 ml-2"></span><span className="step  mr-2 ml-2"></span><span className="step  mr-2 ml-2"></span><span className="step mr-2 ml-2"></span><span className="step mr-2 ml-2"></span>
                                </div>
                                <p className="mt-2 text-quaternary text-size-20">{i18n.t('signup.step1.header.current_step')}</p>
                            </div>
                        
                            <div className = "main-wrapper">
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className="white-cover text-left"
                                >
                                    <div className="col-sm-12 pb-3 pl-0 pr-0">
                                        <TextValidator
                                            className={'form-control form-control-lg'}
                                            autoComplete="off"
                                            placeholder={i18n.t('signup.step1.enter_first_name')}
                                            type="text"
                                            name="first_name"
                                            value={this.state.first_name || ""}
                                            onChange={this.handleChange}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            validators={['required', "maxStringLength:40"]}
                                            errorMessages={[i18n.t('login.errors.first_name_required'), i18n.t('login.errors.first_name_max_length')]} />
                                    </div>
                                    <div className="col-sm-12 pb-3 pl-0 pr-0">
                                        <TextValidator
                                            className={'form-control form-control-lg'}
                                            autoComplete="off"
                                            placeholder={i18n.t('signup.step1.enter_last_name')}
                                            type="text"
                                            name="last_name"
                                            value={this.state.last_name || ""}
                                            onChange={this.handleChange}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            validators={['required', "maxStringLength:40"]}
                                            errorMessages={[i18n.t('login.errors.last_name_required'), i18n.t('login.errors.last_name_max_length')]} />
                                    </div>
                                    <div className="col-sm-12 pb-3 pl-0 pr-0">
                                        <TextValidator
                                            className={'form-control form-control-lg'}
                                            autoComplete="off"
                                            placeholder={i18n.t('signup.step1.enter_email')}
                                            type="text"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            validators={['required', 'isEmail']}
                                            errorMessages={[i18n.t('login.errors.email_required'), i18n.t('login.errors.email_invalid')]} />
                                    </div>
                                    <div className="col-sm-12 pb-2 pl-0 pr-0">
                                        <TextValidator
                                            className={'form-control form-control-lg'}
                                            autoComplete="off"
                                            placeholder={i18n.t('signup.step1.enter_pass')}
                                            type="password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            validators={['required', 'minStringLength:8']}
                                            errorMessages={[i18n.t('login.errors.password_required'), i18n.t('signup.step1.errors.password_max_char')]} />
                                    </div>
                                    <div className="custom-control custom-checkbox my-4">
                                        <CheckboxValidator
                                            className={'custom-control-input'}
                                            autoComplete="off"
                                            type="checkbox"
                                            name="customCheck1"
                                            id="customCheck1"
                                            value={this.state.customCheck1}
                                            onChange={(e) => this.handleChange(e, 'checkbox')}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            labelclass="custom-control-label text-accentb text-size-18"
                                            label={i18n.t('signup.step1.accept_tos')}
                                            validators={['isChecked']}
                                            errorMessages={[i18n.t('signup.step1.errors.tos_required')]} />
                                        &nbsp; <a href={`/`+this.state.language+`/tos`} target="no_blank" className="text-accentb text-size-18 text-uline">{i18n.t('signup.step1.accept_tos_link_txt')}</a>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <CheckboxValidator
                                            className={'custom-control-input'}
                                            autoComplete="off"
                                            type="checkbox"
                                            name="customCheck2"
                                            id="customCheck2"
                                            value={this.state.customCheck2}
                                            onChange={(e) => this.handleChange(e, 'checkbox')}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            labelclass="custom-control-label text-accentb text-size-18"
                                            label={i18n.t('signup.step1.accept_pol')}
                                            validators={['isChecked']}
                                            errorMessages={[i18n.t('signup.step1.errors.pol_required')]} />
                                        &nbsp; <a target="no_blank" href={getPrivacyURL()} className="text-accentb text-size-18 text-uline">{i18n.t('signup.step1.accept_pol_link_txt')}</a>
                                    </div>
                                    <button type="submit" className={'btn btn-accenta btn-lg btn-block mt-4'}> {i18n.t('signup.step1.btn_continue')}</button>
                                </ValidatorForm>
                                <Errors hasError={this.state.hasError} status={this.state.status} type="warning" dynemichtml= {this.state.dynemichtml || false} message={this.state.errorMessage}/>
                            </div>
                        </div>
                        <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="mobile mt-3" redirectPath={this.state.pathname+"/"+this.props.location.search} /> 
                    </div>
                </div> 
            } ></LandingStepBase>
            <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="desktop lang-top-right" redirectPath={this.state.pathname+"/"+this.props.location.search} />
         </React.Fragment>
        )
    }
}

export default withTranslation()(PackSignUp);