// PAYMENT STEP #3 : signup/payment
import React from 'react';
import ReactDOM from 'react-dom';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import DropIn from "braintree-web-drop-in-react";
import API from '../api';
import i18n from '../../packs/i18n';
import PropTypes from 'prop-types'
import { getLocalUrl, setIsUserInfoReload } from '../Common/i3library';
import Loading from '../Common/Loading';
import { withTranslation } from 'react-i18next';
import { PACK_FREE_IMG, PACK_INDI_IMG, PACK_TEAM_IMG } from "../Common/assets_paths";
import { createEvent } from '../Common/AnalyticsEvents';
//import braintree from '../../packs/braintree.client.min';
//import threeDSecure from '../../packs/braintree.three-d-secure.min';
const renderSubmitButton = ({ onClick, isDisabled, text }) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
    >{text}</button>
  )
}

renderSubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
}


class Payment extends React.Component {

    constructor(props) {
        super(props);
        var base_price, dis_txt, extra_years_txt;
        const { state } = props.location;
        if(!state && !window.history.state){
            props.history.goBack();
        }
        if(props.location.state){
            if (props.location.state.selectedType === "month") {
                base_price = props.location.state.selected_sub_plan ? props.location.state.planDetail.sub_plans[props.location.state.selected_sub_plan].monthly_price : props.location.state.planDetail.monthly_price;
                dis_txt = 'signup.step5.month_txt';
            }
            else {
                base_price = props.location.state.selected_sub_plan ? props.location.state.planDetail.sub_plans[props.location.state.selected_sub_plan].yearly_price : props.location.state.planDetail.yearly_price;
                dis_txt = 'signup.step5.year_txt';
            }
            //ILEARN-3823 - If voucher code avail.
            const {voucherInfo} = props.location.state;
            if(voucherInfo){
              if(props.location.state.selectedType === "month"){
                base_price = voucherInfo.monthly_discounted_amount;
              }else{
                base_price = voucherInfo.yearly_discounted_amount;
              }
              if(voucherInfo.percentage === 100){
                dis_txt = 'signup.step5.year_txt';
                if(voucherInfo.license_duration && voucherInfo.license_duration > 1)
                {
                  let license_duration = voucherInfo.license_duration
                  extra_years_txt = i18n.t('signup.step5.extra_years_txt') + license_duration + ' '+i18n.t('signup.step5.years_txt')
                }
                base_price = voucherInfo.yearly_discounted_amount;
              }
            }
        }
        this.state = {
            clientToken: null,
            per_pr_txt: dis_txt ? dis_txt : '',
            calcPrice: base_price ? base_price : 0,
            loading: false,
            selected_payment_method: null,
            you_save_price: null,
            extra_years_txt: extra_years_txt || ''
        };
    }

    async componentDidMount() {
      // Get a client token for authorization from your server
      if(this.props.location.state){
        if (this.props.location.state.planDetail.planType !== 'free') {
          this.setState({
            you_save_price: this.props.location.state.you_save_price
          });
          const response = await fetch("/react_landing/bt_token");
          const clientToken = await response.json(); // If returned as JSON string

          this.setState({
            clientToken: clientToken.message
          });
        }
        if(this.props.location.state.typeAction === 'expiredRenew'){
          let backBtnElement = document.querySelector('.btn-back');
          backBtnElement.onclick = () => {
            this.props.history.go(-1);
          };
        }
      } else {
        const userInfo = await API.get('/users/info').then(res => { if(res.data.user){ return res.data.user; }});
        const subscriptionData = await API.get('subscription/active').then(res => { if(res.data){ return res.data; }});
        if(subscriptionData){
          const planDetail = {
            planType: subscriptionData.key,
            monthly_price : subscriptionData.monthly_price,
            yearly_price : subscriptionData.yearly_price,
            sub_plans: null,
            userInfo: null,
            display_text: subscriptionData.display_text
          }
          this.props.history.push({
            pathname: this.props.location.pathname,
            state: {
              planDetail:planDetail,
              selectedType: subscriptionData.payment_cycle === 'monthly' ? 'month' : 'yearly',
              voucherCode: null,
              voucherInfo: null,
              selected_sub_plan: null,
              you_save_price: null,
              team_name: '',
              currentPlan: subscriptionData,
              action: 'renew',
              typeAction : 'expiredRenew'
            }
          });
        }
        // 4062 : Account - Upgrade through profile loop
        // this.props.history.push({
        //     pathname: getLocalUrl(this.props.match.params.locale, `signup/selectpackage/${userInfo.persistence_token}`),
        //     state: { currentPlan: subscriptionData }
        // });
      }
    }

    async buy() {
        const { currentPlan, upgradePlan, planDetail } = this.props.location.state;
        const payment = {
            persistence_token: this.props.match.params.token_id,
            subscription: {
                plan: planDetail.planType,
                persistence_token: this.props.match.params.token_id,
                action: (this.props.location.state.action || (upgradePlan? 'upgrade_plan' : 'signup') )
            }
        }
        if(this.props.location.state && this.props.location.state.voucherInfo && this.props.location.state.voucherInfo.percentage === 100) {
            payment.subscription.plan = this.props.location.state.selected_sub_plan ? this.props.location.state.selected_sub_plan : this.props.location.state.planDetail.planType,
            payment.subscription.voucher = this.props.location.state.voucherCode;
            payment.payment_method_type = 'wire_transfer';
            payment.subscription.payment_cycle = 'yearly';
            payment.team_name = this.props.location.state.team_name;
            payment.to_be_process = false;
            this.processThePayment(payment)
        }
        else if (planDetail.planType && planDetail.planType !== 'free') {
            let threedsamount = null;
            if (this.props.location.state.voucherInfo) {
              if(this.props.location.state.selectedType === "month"){
                threedsamount = this.props.location.state.voucherInfo.monthly_discounted_amount;
              }else{
                threedsamount = this.props.location.state.voucherInfo.yearly_discounted_amount;
              }
            }
            else{
              threedsamount = this.state.calcPrice;
            }

            let threeDSecureParameters = {
              amount: threedsamount
            }
            const {nonce} = await this.instance.requestPaymentMethod();
            payment.subscription.nonce = nonce;
            const _this = this
            this.setState({
              loading : true
            })
            API.post('react_landing/payment_method_token', payment)
            .then(res => {
              let paymentNonce = res.data.nonce;
              this.instance.requestPaymentMethod({threeDSecure: threeDSecureParameters}).then(payload => {
                if(payload.type == 'PayPalAccount'){
                  payment.is_paypal = true;
                  payment.subscription.nonce = payload.nonce;
                  payment.subscription.plan = _this.props.location.state.selected_sub_plan ? _this.props.location.state.selected_sub_plan : _this.props.location.state.planDetail.planType,
                  payment.subscription.payment_cycle = _this.props.location.state.selectedType === 'month' ? 'monthly' : 'yearly',
                  payment.subscription.voucher = _this.props.location.state.voucherCode;
                  payment.team_name = _this.props.location.state.team_name;
                  payment.payment_method_type = _this.state.selected_payment_method;
                  _this.processThePayment(payment)
                }
                else{
                  braintree.threeDSecure.create({
                    authorization: this.state.clientToken,
                    version: 2
                  },
                  function (threeDSecureError, threeDSecureInstance) {
                    if (threeDSecureError) {
                      if(upgradePlan)
                        createEvent("Upgrade", "Payment fail", threeDSecureError.message || "threeDSecureError");
                      else
                        createEvent("Signup", "Payment fail", threeDSecureError.message || "threeDSecureError");
                        _this.props.history.push({
                          pathname: getLocalUrl(_this.props.match.params.locale, 'signup/fail'),
                          state: { success: false }
                      });
                      _this.setState({ loading: false });
                      // Handle initialization errors here
                      return;
                    }
  
                    threeDSecureInstance.verifyCard({
                        amount: threedsamount,
                        nonce: paymentNonce,
                        bin: payload.details.bin,
                        onLookupComplete: function (data, next) {
                          // Use look-up data here
                          next();
                        }
                      },
                      function (verifyError, verifyResponse) {
                        if (verifyError) {
                          if(upgradePlan)
                            createEvent("Upgrade", "Payment fail", verifyError.message || "Payment Fail due to threedsecure verify card");
                          else
                            createEvent("Signup", "Payment fail", verifyError.message || "Payment Fail due to threedsecure verify card");
                            _this.props.history.push({
                              pathname: getLocalUrl(_this.props.match.params.locale, 'signup/fail'),
                              state: { success: false }
                          });
                          _this.setState({ loading: false });
                          return;
                        }
                      // Submit verifyResponse.nonce to your server
    
                        //let nonce = "fake-valid-nonce"
                        payment.subscription.nonce = verifyResponse.nonce;
                        payment.subscription.plan = _this.props.location.state.selected_sub_plan ? _this.props.location.state.selected_sub_plan : _this.props.location.state.planDetail.planType,
                        payment.subscription.payment_cycle = _this.props.location.state.selectedType === 'month' ? 'monthly' : 'yearly',
                        payment.subscription.voucher = _this.props.location.state.voucherCode;
                        payment.team_name = _this.props.location.state.team_name;
                        payment.payment_method_type = _this.state.selected_payment_method;
                        _this.processThePayment(payment)
                      });
                  });
                }
                
              })
            })
            .catch((error) => {
              if(upgradePlan)
                createEvent("Upgrade", "Payment fail", error.response.data.message);
              else
                createEvent("Signup", "Payment fail", error.response.data.message);
              this.props.history.push({
                  pathname: getLocalUrl(this.props.match.params.locale, 'signup/fail'),
                  state: { success: false }
              });
              this.setState({ loading: false });
          })
          .finally( () => {

          });
        } else {
            payment.subscription.payment_cycle = 'yearly';
            this.processThePayment(payment)
        }
    }
    
    processThePayment(payment){
      const { currentPlan, upgradePlan, planDetail } = this.props.location.state;
      API.post('react_landing/process_payment', payment)
          .then(res => {
              const { state } = this.props.location;
              setIsUserInfoReload(true);
              let sendEventmsg =  payment.subscription.payment_cycle;
              sendEventmsg+="__"+payment.subscription.plan;
              sendEventmsg+= "__"+this.state.selected_payment_method//this.state.hasTeamPlan? "__"+this.state.team_size : "__"+this.props.location.state.planType;
              if(upgradePlan)
                createEvent("Upgrade", "Payment success", sendEventmsg);
              else
                createEvent("Signup", "Payment success", sendEventmsg);
              this.props.history.push({
                  pathname: getLocalUrl(this.props.match.params.locale, 'signup/success'),
                  state: { success: true, currentPlan: currentPlan, upgradePlan, selectedPlan: state ? state.planDetail.planType : null, is100: this.props.location && this.props.location.state && this.props.location.state.voucherInfo && this.props.location.state.voucherInfo.percentage === 100 }
              });
          })
          .catch((error) => {
            
              if(upgradePlan)
                createEvent("Upgrade", "Payment fail", error.response.data.message);
              else
                createEvent("Signup", "Payment fail", error.response.data.message);
              this.props.history.push({
                  pathname: getLocalUrl(this.props.match.params.locale, 'signup/fail'),
                  state: { success: false }
              });
              this.setState({ loading: false });
          })
          .finally( () => {

          });
    }
    render() {
        if(!this.props.location.state){
          return <Loading />
        }
        let braintree_dropin;
        let is100 = false;
        if (!this.state.clientToken && this.props.location.state.planDetail.planType != 'free') {
          //
        }
        else if(this.props.location && this.props.location.state && this.props.location.state.voucherInfo && this.props.location.state.voucherInfo.percentage === 100){
            //
            is100 = true;
        }
        else {
          let threedsamount = null;
          if (this.props.location.state.voucherInfo) {
            if(this.props.location.state.selectedType === "month"){
              threedsamount = this.props.location.state.voucherInfo.monthly_discounted_amount;
            }else{
              threedsamount = this.props.location.state.voucherInfo.yearly_discounted_amount;
            }
          }
          else{
            threedsamount = this.state.calcPrice;
          }
          braintree_dropin = <DropIn
            options={{
              authorization: this.state.clientToken,
              //threeDSecure: true,
              paypal: {
                displayName: "i3LEARNHUB",
                flow: 'vault',
                amount: threedsamount,
                currency: "EUR",
                buttonStyle: {
                  color: 'blue',
                  shape: 'rect',
                  size: 'medium'
                },
                commit: true
              },
              locale: 'nl_NL',
              card: {
                cardholderName: true
              }
            }}
            renderSubmitButton={renderSubmitButton}
            onInstance={instance => {
              this.instance = instance;
              this.instance.on('paymentMethodRequestable', (event) => {
                if (event.paymentMethodIsSelected) {
                  if(event.type && event.type == 'PayPalAccount'){
                    this.buy();
                  }
                } else {
                  // console.log("show buy button");
                }
                this.setState({
                  isPaymentMethodRequestable: true,
                  selected_payment_method: event.type
                });
              });
              this.instance.on('noPaymentMethodRequestable', (event) => {
                this.setState({
                  isPaymentMethodRequestable: false,
                });
              });
            }}
          />;
        }
        let img_path = null;
        let pack_color = null;
        if(this.props.location.state.planDetail.planType === "free"){
            img_path = PACK_FREE_IMG;
            pack_color = "#e5c5e8";
        }
        else if(this.props.location.state.planDetail.planType === 'individual'){
            img_path = PACK_INDI_IMG
            pack_color = "#acb5da";
        }
        else if(this.props.location.state.planDetail.planType === 'team'){
            img_path = PACK_TEAM_IMG
            pack_color = "#bfd0e8";
        }
        if(this.state.loading) {
          return <Loading />
        }
        return (
            <LandingStepBase container={
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 p-0 text-center">
                            <div className="headtxt mt-5 mb-5">
                                <h1 className="text-white mb-0">{i18n.t('signup.step5.header.title')}</h1>
                                <p>{i18n.t('signup.step5.header.sub_title')}</p>
                                <div className="steps">
                                    <span className="completed step mr-2 ml-2"></span><span className="completed  step  mr-2 ml-2"></span><span className="completed step  mr-2 ml-2"></span><span className="completed step mr-2 ml-2"></span><span className="activestep step mr-2 ml-2"></span>
                                </div>
                                <p className="mt-2 text-quaternary text-size-20">{i18n.t('signup.step5.header.current_step')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-wrapper-lg text-center text-primary">
                        <div className="white-cover">
                            <p className="text-size-22">{i18n.t('signup.step5.title_txt')}</p>
                            <div className="payment-option-wrapper mt-4">
                                <div className="payment-option shadow-lg">
                                    <label className="custom-control-selection" htmlFor="chk2"></label>
                                    <div className="custom-control custom-radio selector-tick">
                                        <input type="radio" className="custom-control-input" id="chk2" name="paymenttype" defaultChecked="checked" />
                                        <label className="custom-control-label" htmlFor="chk2"></label>
                                    </div>
                                    <img src={img_path} />
                                    <div className="option-details">
                                        <h5 className="text-secondary">{i18n.t(this.props.location.state.planDetail.display_text)}</h5>
                                        <p className="text-accentb m-0 mb-1">€ <span className="pay-price">{this.state.calcPrice}</span> / {i18n.t(this.state.per_pr_txt)}</p>
                                        {
                                            this.props.location.state.voucherInfo  ?
                                              <div>
                                                  <span className="text-accenta">{this.props.location.state.voucherInfo.percentage}% {i18n.t('signup.step5.by_vchr_txt')}</span>
                                                  <br />
                                                  <span className="text-accenta">{i18n.t('signup.step5.disc_txt')} {this.props.location.state.voucherInfo.you_save_price}€</span>
                                                  <br />
                                              </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                              this.props.location.state.planDetail.planType && this.props.location.state.planDetail.planType != 'free' && !is100 ?
                                <React.Fragment>
                                    <p className="mt-5 text-size-22 ">{i18n.t('signup.step5.payment_txt')}</p>
                                    <div className="payment-gateway-wrapper mt-3">
                                      {braintree_dropin}
                                    </div>
                                    {this.state.isPaymentMethodRequestable ? <a href="javascript:void(0)" onClick={this.buy.bind(this)} className="btn btn-accenta btn-block mb-2 mt-4 text-size-20 m-auto" style={{ width: '200px' }}>{i18n.t('signup.step5.confirm')}</a> : null}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <span className="text-accentab">{this.state.extra_years_txt}</span> 
                                  <br/> <br/>
                                  <a href="javascript:void(0)" className="btn btn-accenta btn-block mb-2 mt-4 text-size-20 m-auto" style={{ width: '200px' }} onClick={this.buy.bind(this)}>{i18n.t('signup.step5.confirm')}</a>
                                </React.Fragment>
                            }

                        </div>
                    </div>
                </div>} subcontainer={null}></LandingStepBase>
        )
    }
}

export default withTranslation()(Payment);
