// Import React Libraries from the react js
import React, {Suspense} from "react";
import { BrowserRouter, Route, Switch, browserHistory } from "react-router-dom";
import { Router } from 'react-router';
import ErrorBoundary from "./ErrorBoundry";
import { CSSTransition, TransitionGroup, Transition } from "react-transition-group";
/*import {TimelineLite as Timeline} from "greensock";*/
import { play, exit, enterDuration } from './Animation/timelines';
import { getQueryParam } from './Common/i3library';
//All Components are defined here and we can navigate each component when click any of the components
import Devnav from "./Common/Devnav";
import Login from "./Login/Login";
import Signup from "./SignUp/SignUp";
import Tos from "./PrivacyPolicy/Tos";
import SignupConfirmEmail from "./SignUp/ConfirmEmail";
import SignupSelectPackage from "./SignUp/SelectPackage";
import Pricing from "./SignUp/Pricing";
import SignupPaymentMethod from "./SignUp/PaymentMethod";
import SignupPayment from "./SignUp/Payment";
import SignupSuccess from "./SignUp/Success";
import SignupFail from "./SignUp/SignupFail";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import AcceptOrDecline from "./Invitations/AcceptOrDecline";
import ForgotPasswordConfirmEmail from "./ForgotPassword/ConfirmEmail";
import ForgotPasswordChooseNewPassword from "./ForgotPassword/ChooseNewPassword";
import ForgotPasswordSuccess from "./ForgotPassword/Success";
import ConnectSession from "./Connect/ConnectSession";
import Fail from "./ForgotPassword/Fail";
import NotFound from "./Common/NotFound";
import UserProfileLanding from "./UserProfile/Landing";
import { setAvailableLanguages, setPrivacyURL, setTeamPackPrefixArray } from "./Common/i3library";
import ConfirmSignup from "./UserProfile/ConfirmSignup";
import Loading from './Common/Loading';
import { createBrowserHistory, createMemoryHistory } from 'history';
//import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
const history = createBrowserHistory();
import withTracker from './Common/withTracker';
/* Pack Signup Routes */
import PackWelcome from "./PackSignup/PackWelcome";
import PackSignUp from "./PackSignup/PackSignUp";
import PackConfirmEmail from "./PackSignup/PackConfirmEmail";
import PackKeyActivate from "./PackSignup/PackKeyActivate";
import PackSucess from "./PackSignup/PackSucess";
import PackFail from "./PackSignup/PackFail";
import ConnectSessionMessage from "./Connect/ConnectSessionMessage";
import PpdsPackWelcome from "./PackSignup/PpdsPackWelcome";
/* Pack Signup Routes  End*/
import AdminLanding from "./Admin/Landing";
import ConsentScreenVLE from "./Common/ConsentScreenVLE";
class Landing extends React.Component {
  constructor(props){
    super(props);
    const { pathname, key } = location;
    if(this.props.analytics_track_id)
      ReactGA.initialize(this.props.analytics_track_id);
    this.state = {
      prevPath: {},
      fastTrackInfo: this.props.fastrack_code,
      language_regex: `(${this.props.available_languages.join("|")})`,
      user_language: this.props.user_language,
      is_distributor: this.props.is_distributor || false,
      is_reseller: this.props.is_reseller || false,
      is_top_admin: this.props.is_top_admin || false,
      is_institution_admin: this.props.is_institution_admin || false,
      top_admin_email: this.props.top_admin_email || '',
      is_not_grp_admin: (!this.props.is_distributor && !this.props.is_reseller && !this.props.is_institution_admin)
    };
    this.validateLanguage();
    setAvailableLanguages(this.props.rails_language?this.props.rails_language:null);
    setTeamPackPrefixArray(this.props.pack_team_prefix_array? this.props.pack_team_prefix_array : null)
    setPrivacyURL(this.props.privacy_url)
    let path_array = pathname.split('/')
    let view_id = path_array[path_array.length - 1]
    let search_Term = path_array[path_array.length - 3]
    this.validProfileUrlList = [
      `/${this.props.user_language}/users/profile`,
      `/${this.props.user_language}/users/profile/connect-to-apps`,
      `/${this.props.user_language}/users/profile/admin-your-apps`,
      `/${this.props.user_language}/users/profile/links-accounts`,
      `/${this.props.user_language}/users/profile/manage-subscriptions`,
      `/${this.props.user_language}/users/profile/preferences`,
      `/${this.props.user_language}/users/profile/connect-to-apps/pro`,
      `/${this.props.user_language}/users/profile/links-accounts/pro`,
      `/${this.props.user_language}/users/profile/preferences/pro`,
      `/${this.props.user_language}/users/profile/change_password`];
    this.validAdminUrlList = [
      `/${this.props.user_language}/admin/users/paid`,
      `/${this.props.user_language}/admin/users/free`,
      `/${this.props.user_language}/admin/users/pack`,
      `/${this.props.user_language}/admin/user/view/${view_id}`,
      `/${this.props.user_language}/admin/institutions/paid`,
      `/${this.props.user_language}/admin/institutions/pack`,
      `/${this.props.user_language}/admin/institution/view/${view_id}`,
      `/${this.props.user_language}/admin/distributors`,
      `/${this.props.user_language}/admin/distributor/view/${view_id}`,
      `/${this.props.user_language}/admin/reseller/view/${view_id}`,
      `/${this.props.user_language}/admin/invitations/user`,
      `/${this.props.user_language}/admin/invitations/institute`,
      `/${this.props.user_language}/admin/invitations/distributor`,
      `/${this.props.user_language}/admin/invitations/reseller`,
      `/${this.props.user_language}/admin/payments`,
      `/${this.props.user_language}/admin/vouchers`,
      `/${this.props.user_language}/admin/subscription_reminders`,
      `/${this.props.user_language}/admin/email`,
      `/${this.props.user_language}/admin/manage_pages`,
      `/${this.props.user_language}/admin/manage_pages/new`,
      `/${this.props.user_language}/admin/manage_pages/edit/${view_id}`,
      `/${this.props.user_language}/admin/oauth`,
      `/${this.props.user_language}/admin/oauth/view/${view_id}`,
      `/${this.props.user_language}/admin/search/${search_Term}/location/user`,
      `/${this.props.user_language}/admin/search/${search_Term}/location/institute`,
      `/${this.props.user_language}/admin/search/${search_Term}/location/distributor`,
      `/${this.props.user_language}/admin/search/${search_Term}/location/invitation`,
      `/${this.props.user_language}/admin/search/${search_Term}/location/all`,
      `/${this.props.user_language}/admin/libraries`,
      `/${this.props.user_language}/admin/activelearning`,
      `/${this.props.user_language}/admin/keyboards`,
      `/${this.props.user_language}/admin/preferences`,
      `/${this.props.user_language}/admin/educationallinks`
    ]
  }

  validateLanguage = () =>{
    const { pathname, key } = location;
    const { user_language, language_regex } = this.state;
    const currentLanguage = pathname.split('/')[1];
    const isLangAvail = language_regex.includes(currentLanguage);
    //ILEARN-4348 Handling for the Connect Session Code not Redirect with code because language is missing
    const values = getQueryParam(history.location.search);


    if (values && values.code) {
      if(!isLangAvail){
        let pathName = `/${user_language}${location.pathname}?code=`+values.code;
        window.location.href = window.location.origin+pathName;
      }
    }
    else{// ILEARN-4348 End
      if(!isLangAvail){
        history.push({
          pathname : `/${user_language}${location.pathname}`
        });
      }
    }
    if(!key && user_language && isLangAvail && user_language !== currentLanguage){
      history.goBack();
    }
  }
  componentDidMount() {

  }

  handleProfileChange = () => {
    const { state } = history.location;
    const isUpgradeOrRenewOrProfileChange = state ? (state.action === 'upgrade' || state.action === 'renew' || state.action === 'save' ? true : false) : false;
    if((this.validProfileUrlList.includes((location.pathname).replace(/\/$/, ""))) || isUpgradeOrRenewOrProfileChange){
      return <UserProfileLanding />;
    }else{
      return <NotFound />;
    }
  }
  handleAdminChange = () => {
    if( !this.state.is_distributor && !this.state.is_reseller && !this.state.is_institution_admin && !this.state.is_top_admin) {
      return <NotFound />;
    }
    const { state } = history.location;
    // const isUpgradeOrRenewOrProfileChange = state ? (state.action === 'upgrade' || state.action === 'renew' || state.action === 'save' ? true : false) : false;
    //if((location.pathname).replace(/\/$/, "").indexOf('/admin') !== -1){
    if((this.validAdminUrlList.includes((location.pathname).replace(/\/$/, "")))){
      return <AdminLanding {...this.props} is_distributor={this.state.is_distributor} is_reseller={this.state.is_reseller} is_institution_admin={this.state.is_institution_admin} is_top_admin={this.state.is_top_admin} top_admin_email={this.state.top_admin_email} is_not_grp_admin={this.state.is_not_grp_admin} />;
    }else{
      return <NotFound />;
    }
  }
  render() {
    return (
      <Router history={history}>
        <ErrorBoundary>
          <div className="app">
            <Route  render={({ location, action }) => {
                const { pathname, key } = location;
                const unlisten = history.listen((location, action) => {
                  // console.log("history info", this.props, history, location, action, location.pathname, location.key);
                });
                return (
                <TransitionGroup>
                  {/* <CSSTransition key={location.key} timeout={0} classNames="fade"> */}
                  <Transition
                      key={key}
                      appear={true}
                      onEnter={(node, appears) => play(pathname, node, appears, key)}
                      onExit={(node) => exit(pathname, node, key)}
                      timeout={{enter: enterDuration(), exit: 550}}
                  >
                    <Suspense fallback={<Loading />}>
                    <Switch location={location} >
                      <Route exact path={`/:locale${this.state.language_regex}?/login`} component={withTracker(() => <Login {...this.props} />)}/>
                      <Route exact path={`/:locale${this.state.language_regex}?/signup`} component={withTracker(Signup)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/pricing`} component={withTracker(() => <Pricing {...this.props} /> ) } />
                      <Route exact path={`/:locale${this.state.language_regex}?/tos`} component={withTracker(Tos)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/signup/confirmemail`} component={withTracker(SignupConfirmEmail)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/signup/selectpackage/:token_id`} component={withTracker(SignupSelectPackage)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/signup/paymentmethod/:token_id`} component={withTracker(SignupPaymentMethod)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/signup/payment/:token_id`} component={withTracker(SignupPayment)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/signup/success`} component={withTracker(SignupSuccess)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/signup/fail`} component={withTracker(SignupFail)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/forgotpassword`} component={withTracker(ForgotPassword)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/invitations/accept`} component={withTracker(AcceptOrDecline)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/forgotpassword/confirmemail`} component={withTracker(ForgotPasswordConfirmEmail)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/forgotpassword/choosenewpassword/:token_id`} component={withTracker(ForgotPasswordChooseNewPassword)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/forgotpassword/success`} component={withTracker(ForgotPasswordSuccess)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/forgotpassword/fail`} component={withTracker(Fail)} />
                      <Route path={`/:locale${this.state.language_regex}?/users/profile`} component={ withTracker(this.handleProfileChange) } />
                      <Route exact path={`/:locale${this.state.language_regex}?/users/confirm_sign_up`} component={withTracker(ConfirmSignup)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/start`} component={withTracker(ConnectSession)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/start_message`} component={withTracker( () => <ConnectSessionMessage {...this.props} />)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup`} component={withTracker(PackWelcome)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup/register`} component={withTracker(PackSignUp)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup/confirmemail`} component={withTracker(PackConfirmEmail)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup/success`} component={withTracker(PackSucess)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup/fail`} component={withTracker(PackFail)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup/activate/:token_id`} component={withTracker(PackKeyActivate)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup/:pack_key`} component={withTracker(PackWelcome)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup/:pack_key/register`} component={withTracker(PackSignUp)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/packsignup/:pack_key/activate/:token_id`} component={withTracker(PackKeyActivate)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/ppdsregisterpack`} component={withTracker(PpdsPackWelcome)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/ppdsregisterpack/:pack_key`} component={withTracker(PpdsPackWelcome)} />
                      <Route exact path={`/:locale${this.state.language_regex}?/consent_vle/:brd_id/:tkn/:data`} component={withTracker(() => <ConsentScreenVLE {...this.props} />)}/>
                      <Route exact path={`/:locale${this.state.language_regex}?/consent_vle/:brd_id/:tkn/:data/:ukid`} component={withTracker(() => <ConsentScreenVLE {...this.props} />)}/>
                      <Route path={`/:locale${this.state.language_regex}?/admin`} component={ withTracker(this.handleAdminChange) } />
                      <Route exact path={`/`} component={() => { window.location.reload(); }} />
                      <Route component={withTracker(NotFound)} />
                      
                    </Switch>
                    </Suspense>
                </Transition>
                </TransitionGroup>
                )
              }}
            />
          </div>
        </ErrorBoundary>
      </Router>
    )
  }
}


export default Landing;
